document.addEventListener('DOMContentLoaded', function () {

    function accordion() {
        let accordions = document.querySelectorAll('.faq-item');

        accordions.forEach(el => {
            el.addEventListener('click', (e) => {
                const self = e.currentTarget;
                const content = self.querySelector('.faq-item__descr');

                self.classList.toggle('open');

                if (self.classList.contains('open')) {
                    content.style.maxHeight = content.scrollHeight + 'px';
                } else {
                    content.style.maxHeight = null;
                }
            });
        });
    }

    if (document.querySelector('.faq-item')) {
        accordion();
    }


    function moreText() {
        let accordions = document.querySelectorAll('.btn-more');

        accordions.forEach(el => {
            el.addEventListener('click', (e) => {
                const self = e.currentTarget;
                const content = document.querySelector('.hide');

                self.classList.toggle('open');

                if (self.classList.contains('open')) {
                    content.style.maxHeight = content.scrollHeight + 'px';
                    content.classList.add('open');
                } else {
                    content.style.maxHeight = null;
                    content.classList.remove('open');
                }
            });
        });
    }

    if (document.querySelector('.faq-item')) {
        moreText();
    }
});
