document.addEventListener('DOMContentLoaded', function () {

    const sliderImg = new Swiper(".slider-img", {
        navigation: {
            nextEl: ".slider-img .swiper-button-next",
            prevEl: ".slider-img .swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        effect: "creative",
        speed: 500,
        allowTouchMove: false,
        creativeEffect: {
            prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        }
    });



    const reviewsSwiper = new Swiper(".reviews-swiper", {
        slidesPerView: 2,
        spaceBetween: 30,
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 10000,
        },
        pagination: {
            el: ".reviews .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".reviews .swiper-button-next",
            prevEl: ".reviews .swiper-button-prev",
        },
        breakpoints: {
            1030: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 1,
            },
        },
    });




    function slideChange(parent) {
        let parentNode = document.querySelector(parent);
        let bullet = parentNode.querySelectorAll('.swiper-pagination-bullet');
        let bulletAllNum = bullet.length;
        let bulletIndex;


        bullet.forEach((item, i) => {
            bulletIndex = i + 1;
            item.innerHTML = `0${bulletIndex}`;
        });
    }

    if (document.querySelector('.slider')) {
        slideChange('.slider');
    }
    if (document.querySelector('.reviews')) {
        slideChange('.reviews');
    }


    const sliderGallerySwiper = new Swiper(".slider-gallery-swiper", {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 30,
        loop: true,
        speed: 1000,
        pagination: {
            el: ".slider-gallery-swiper .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".slider-gallery-swiper .swiper-button-next",
            prevEl: ".slider-gallery-swiper .swiper-button-prev",
        },
        breakpoints: {
            820: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });


});
