document.addEventListener('DOMContentLoaded', function () {

    function btnDisabled(checkboxItem, btn) {
        let checkbox = document.querySelectorAll(checkboxItem);
        let btnSubmit = document.querySelectorAll(btn);

        checkbox.forEach(item => {
            item.addEventListener("change", (e) => {
                if (e.target.checked) {
                    btnSubmit.forEach(item => {
                        item.classList.remove('btn-disabled');
                        item.disabled = false;
                    });
                } else {
                    btnSubmit.forEach(item => {
                        item.classList.add('btn-disabled');
                        item.disabled = true;
                    });
                }
            });
        });
    }

    btnDisabled('.personal-data-check', '.min-form .min-form__btn');
    btnDisabled('.personal-data-check', '.form-two .min-form__btn');
    btnDisabled('.personal-data-check', '.back-call .min-form__btn');
    btnDisabled('.booking-sum-check', '.booking-sum__btn');



    function hedScroll() {
        let lastScroll = 0;
        const header = document.querySelector('.header');

        window.addEventListener("wheel", (e) => {
            const currentScroll = window.pageYOffset;
            if (currentScroll > lastScroll) {
                header.classList.add('hed-fix');
            } else if (currentScroll < lastScroll) {
                header.classList.remove('hed-fix');
            }
            lastScroll = currentScroll;
        });
    }

    hedScroll();


    function menu() {
        const burger = document.querySelector('.burger');
        const nav = document.querySelector('.nav');

        burger.addEventListener('click', function () {
            this.classList.toggle('active');
            nav.classList.toggle('active');
        });

    }

    menu();









});