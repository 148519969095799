document.addEventListener('DOMContentLoaded', function () {


    function radioBlock() {
        const radio = document.querySelectorAll('input[type="radio"]');
        const recurring = document.getElementById('recurring');
        const periodicity = document.querySelector('.periodicity');

        radio.forEach(item => {
            item.addEventListener('click', function () {

                let name = item.getAttribute('name');
                let radioName = item.parentElement.parentElement.querySelectorAll(`input[name="${name}"]`);

                radioName.forEach(item => {
                    item.parentElement.classList.remove('active');
                });

                if (item.checked) {
                    item.parentElement.classList.add('active');
                }

                if (recurring.checked) {
                    periodicity.classList.add('active');
                } else {
                    periodicity.classList.remove('active');
                }
            });
        });
    }

    radioBlock();

    /////////////////////////////////////////////////////////////////////////////

    function checkboxBlock() {
        const checkbox = document.querySelectorAll('.checkbox-block');

        checkbox.forEach(item => {
            item.addEventListener('click', function () {
                item.classList.toggle('active');
            });
        });
    }

    checkboxBlock();

    /////////////////////////////////////////////////////////////////////////////

    function quantityBedroom() {
        let btnPlus = document.querySelector('.quantity-bedroom .btn-plus');
        let btnMinus = document.querySelector('.quantity-bedroom .btn-minus');
        let quantityBedroom = document.querySelectorAll('.quantity-bedroom-num');
        let quantityBedroomInput = document.getElementById('quantity-bedroom');


        btnPlus.addEventListener('click', function () {
            quantityBedroomInput.value++;
            quantityBedroom.forEach(item => {
                item.innerHTML = `${quantityBedroomInput.value}`;
            });
        });

        btnMinus.addEventListener('click', function () {
            quantityBedroomInput.value--;
            quantityBedroom.forEach(item => {
                item.innerHTML = `${quantityBedroomInput.value}`;
            });
            if (quantityBedroomInput.value <= 1) {
                quantityBedroomInput.value = 1;
                quantityBedroom.forEach(item => {
                    item.innerHTML = `${1}`;
                });
            }
        });

    }


    if (document.querySelector('.btn-plus')) {
        quantityBedroom();
    }

    /////////////////////////////////////////////////////////////////////////////

    function quantityBathroom() {
        let btnPlus = document.querySelector('.quantity-bathroom .btn-plus');
        let btnMinus = document.querySelector('.quantity-bathroom .btn-minus');
        let quantityBathroom = document.querySelectorAll('.quantity-bathroom-num');
        let quantityBathroomInput = document.getElementById('quantity-bathroom');


        btnPlus.addEventListener('click', function () {
            let num = Number(quantityBathroomInput.value) + 0.25;

            if (num <= 3) {
                quantityBathroomInput.value = num;
            }

            quantityBathroom.forEach(item => {
                item.innerHTML = `${quantityBathroomInput.value}`;
            });
        });

        btnMinus.addEventListener('click', function () {
            let num = Number(quantityBathroomInput.value) - 0.25;

            if (num >= 0.75) {
                quantityBathroomInput.value = num;
            }

            quantityBathroom.forEach(item => {
                item.innerHTML = `${quantityBathroomInput.value}`;
            });
        });

    }


    if (document.querySelector('.btn-plus')) {
        quantityBathroom();
    }

    /////////////////////////////////////////////////////////////////////////////

    function periodicity() {
        const periodicityRadio = document.querySelectorAll('.periodicity .radio-block');
        let periodicityText = document.querySelectorAll('.periodicity-text');
        let bookingSumPeriodicity = document.querySelectorAll('.booking-sum-periodicity');
        let periodicityRadioText;

        periodicityRadio.forEach(item => {
            item.addEventListener('click', function () {
                let itemText = item.querySelector('span');
                periodicityRadioText = itemText.textContent;

                bookingSumPeriodicity.forEach(item => {
                    item.classList.add('active');
                });

                periodicityText.forEach(item => {
                    item.innerHTML = periodicityRadioText;
                });

            });
        });
    }

    periodicity();

    /////////////////////////////////////////////////////////////////////////////

    function radioTop() {
        const radioTopItem = document.querySelectorAll('input[name=area-radio]');
        const radioBlock = document.querySelectorAll('.radio-block');
        const checkboxBlock = document.querySelectorAll('.checkbox-block');
        let bookingSumResidents = document.querySelectorAll('.booking-sum-residents');
        let bookingSumPeriodicity = document.querySelectorAll('.booking-sum-periodicity');
        let formOne = document.querySelectorAll('.form-one');
        let formTwo = document.querySelector('.form-two');
        let orderWrapper = document.querySelector('.order__wrapper');
        let moveInOutTrue = document.querySelectorAll('.move-in-out-true');
        let moveInOutFalse = document.querySelector('.move-in-out-false');
        let animalItem = document.querySelectorAll('.animal-item');
        let allInputForm = document.querySelectorAll('.order__left-one input');
        let quantityVal1 = document.querySelectorAll('.quantity-val1');
        let quantityNum1 = document.querySelectorAll('.quantity-num1');
        let quantityNum0 = document.querySelectorAll('.quantity-num0');

        radioTopItem.forEach(item => {
            item.addEventListener('click', function () {

                radioBlock.forEach(item => {
                    item.classList.remove('active');
                });

                checkboxBlock.forEach(item => {
                    item.classList.remove('active');
                });
                let af = document.querySelector('input[name=af_action]').value;
                document.getElementById('orderForm').reset();

                item.checked = true;
                item.parentElement.classList.add('active');

                bookingSumResidents.forEach(item => {
                    item.classList.remove('active');
                });

                bookingSumPeriodicity.forEach(item => {
                    item.classList.remove('active');
                });

                animalItem.forEach(item => {
                    item.classList.remove('active');
                });

                allInputForm.forEach(item => {
                    item.value = '';
                });

                quantityVal1.forEach(item => {
                    item.value = 1;
                });

                quantityNum1.forEach(item => {
                    item.innerHTML = '1';
                });

                quantityNum0.forEach(item => {
                    item.innerHTML = '0';
                });

                if (item.classList.contains('commercial-service')) {
                    formOne.forEach(item => {
                        item.classList.add('hide');
                    });
                    formTwo.classList.add('show');
                    orderWrapper.classList.add('v2');
                } else {
                    formOne.forEach(item => {
                        item.classList.remove('hide');
                    });
                    formTwo.classList.remove('show');
                    orderWrapper.classList.remove('v2');
                }

                if (item.classList.contains('radio-block-move')) {
                    moveInOutTrue.forEach(item => {
                        item.style.display = 'flex';
                    });
                    moveInOutFalse.style.display = 'none';
                } else {
                    moveInOutTrue.forEach(item => {
                        item.style.display = 'none';
                    });
                    moveInOutFalse.style.display = 'flex';
                }
                document.querySelector('input[name=af_action]').value = af;
            });
        });
    }

    radioTop();

    /////////////////////////////////////////////////////////////////////////////

    function residents() {
        const residentsItem = document.querySelectorAll('input[name=residents]');
        let residentsText = document.querySelectorAll('.residents-text ');
        let bookingSumResidents = document.querySelectorAll('.booking-sum-residents');
        let residentsRadioText;

        residentsItem.forEach(item => {
            item.addEventListener('click', function () {

                if (item.classList.contains('residents-none')) {

                    bookingSumResidents.forEach(item => {
                        item.classList.remove('active');
                    });

                } else {

                    let itemText = item.parentElement.querySelector('span');
                    residentsRadioText = itemText.textContent;

                    console.log(residentsRadioText);

                    bookingSumResidents.forEach(item => {
                        item.classList.add('active');
                    });

                    residentsText.forEach(item => {
                        item.innerHTML = residentsRadioText;
                    });
                }

            });
        });
    }

    residents();

    function quantityAnimal(parent, animalNum, inputNum, itemAnimal) {
        let btnPlus = document.querySelector(`${parent} .btn-plus`);
        let btnMinus = document.querySelector(`${parent} .btn-minus`);
        let quantityAnim = document.querySelectorAll(animalNum);
        let quantityAnimInput = document.getElementById(inputNum);
        let animalItem = document.querySelectorAll(itemAnimal);


        btnPlus.addEventListener('click', function () {
            quantityAnimInput.value++;
            quantityAnim.forEach(item => {
                item.innerHTML = `${quantityAnimInput.value}`;
            });

            if (quantityAnimInput.value >= 1) {
                animalItem.forEach(item => {
                    item.classList.add('active');
                });
            }
        });

        btnMinus.addEventListener('click', function () {
            quantityAnimInput.value--;
            quantityAnim.forEach(item => {
                item.innerHTML = `${quantityAnimInput.value}`;
            });
            if (quantityAnimInput.value <= 1) {
                quantityAnimInput.value = 0;
                quantityAnim.forEach(item => {
                    item.innerHTML = `${0}`;
                });
                animalItem.forEach(item => {
                    item.classList.remove('active');
                });
            }
        });

    }


    if (document.querySelector('.btn-plus')) {
        quantityAnimal('.quantity-dog', '.dog-item-num', 'quantity-dog', '.dog-item');
        quantityAnimal('.quantity-cat', '.cat-item-num', 'quantity-cat', '.cat-item');
    }


    function whatTime() {
        const whatTimeRadio = document.querySelectorAll('.what-time-radio .radio-block');
        let whatTimeText = document.querySelectorAll('.what-time-text');
        let whatTimeRadioText;

        whatTimeRadio.forEach(item => {
            item.addEventListener('click', function () {
                let itemText = item.querySelector('span');
                whatTimeRadioText = itemText.textContent;

                whatTimeText.forEach(item => {
                    item.innerHTML = whatTimeRadioText;
                });

            });
        });
    }

    whatTime();

    /////////////////////////////////////////////////////////////////////////////

    function quantityBed() {
        let btnPlus = document.querySelector('.quantity-bed-change .btn-plus');
        let btnMinus = document.querySelector('.quantity-bed-change .btn-minus');
        let quantityBedroom = document.querySelectorAll('.bed-change-num');
        let quantityBedroomInput = document.getElementById('bed-change-input');


        btnPlus.addEventListener('click', function () {
            quantityBedroomInput.value++;
            quantityBedroom.forEach(item => {
                item.innerHTML = `${quantityBedroomInput.value}`;
            });
        });

        btnMinus.addEventListener('click', function () {
            quantityBedroomInput.value--;
            quantityBedroom.forEach(item => {
                item.innerHTML = `${quantityBedroomInput.value}`;
            });
            if (quantityBedroomInput.value <= 0) {
                quantityBedroomInput.value = 0;
                quantityBedroom.forEach(item => {
                    item.innerHTML = `${0}`;
                });
            }
        });

    }



    if (document.querySelector('.btn-plus')) {
        quantityBed();
    }






});
